import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const initialState = {
  token: null,
  permissions: [],
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.token = action.payload.token;
      let decoded = jwtDecode(action.payload.token)
      state.permissions = decoded.permissions;
      state.isAuthenticated = true;
      // console.log(action.payload.permissions)
    },
    logout: (state) => {
      state.token = null;
      state.permissions = [];
      state.isAuthenticated = false;
    },
  },
});

export const { setAuthData, logout } = authSlice.actions;
export default authSlice.reducer;
