import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "./App.css";

// Header and utilities
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider, useAuth } from "./utils/AuthContext";
import withPermission from "./utils/Redux_Auth/withPermission";
import { setAuthData } from "./utils/Redux_Auth/authSlice";
import HeaderWrapper from "./HeaderWrapper";

// Lazy loaded components

//login page
import LoginPage from "./components/Login/LoginPage";
//homepage
import Homepage from "./components/Homepage/Homepage";
//anamolies
const Anamolies = lazy(() => import("./components/Anamolies-Data/Anamolies"));
//Admin Dashboard
const AdminDashboard = lazy(() =>
  import("./components/App-Stats/DashboardHome")
);
//BLE Analyzer
const BLEAnalyzer = lazy(() =>
  import("./components/BLE-Connection/BLE_Homepage")
);
//crash details
const CrashDetailsPage = lazy(() =>
  import("./components/CrashDetails/CrashMain")
);
const CrashReportPage = lazy(() =>
  import("./components/CrashDetails/ReportDetails/ReportMain")
);
//user insights
const UserInsightsPage = lazy(() =>
  import("./components/UserInsights/UserInsightsMain")
);
const UserFullView = lazy(() =>
  import("./components/UserInsights/UserFullView")
);
//Email signature
const EmailSignature = lazy(() =>
  import("./components/Email-Signature/EmailSignatureGenerator")
);
const EventDetailsPage = lazy(() =>
  import("./components/EventDetails/EventDetails")
);
//Performance
const Performance = lazy(() =>
  import("./components/Homepage/QuinPerformanceCards/performanceUI")
);

//PerformanceText
const PerformanceText = lazy(() =>
  import("./components/Performance/ContentText/PerformanceContent")
);
const PerformanceImages = lazy(() =>
  import("./components/Performance/ContentImages/PerformanceImage")
);
//PerformanceProducts
const PerformanceProducts = lazy(() =>
  import("./components/Performance/Products/PerformanceProducts")
);

const ProductFullView = lazy(() =>
  import("./components/Performance/Products/FullView/ProductFullView")
);


//PerfoarmanceSensors
const PerformanceSensors = lazy(() => import("./components/Performance/SensorsList/PerformanceSensors"))
const SensorFullView = lazy(() => import("./components/Performance/SensorsList/FullView/SensorFullView"))
//PerformanceProductGroups
const PerformanceProductGroups = lazy(() =>
  import("./components/Performance/ProductGroups/GroupList")
);
const PerformanceProductGroupFullView = lazy(() =>
  import("./components/Performance/ProductGroups/GroupFullView/GroupFullView")
);
//PerformanceUsers
const PerformanceUsers = lazy(() =>
  import("./components/Performance/Users/PerformanceUsers")
);
const PerformanceUsersDetails = lazy(() =>
  import("./components/Performance/Users/UserDetailsTab")
);
//PerformancePolicies
const PerformancePolicies = lazy(() =>
  import("./components/Performance/Policies/PoliciesMain")
);
const PerformancePoliciesDetails = lazy(() =>
  import("./components/Performance/Policies/PolicyDetails")
);

//QuinRide

const QuinRide = lazy(() =>
  import("./components/Homepage/QuinRideCards/QuinRideCards")
);

//QuinRideText
const QuinRideText = lazy(() =>
  import("./components/QuinRide/ContentText/QuinRideContent")
);
//QuinRideImage
const QuinRideImage = lazy(() =>
  import("./components/QuinRide/ContentImages/QuinRideImage")
);
//QuinRideProducts
const QuinRideProducts = lazy(() =>
  import("./components/QuinRide/ProductsList/QuinRideProducts")
);
const QuinRideProductFullView = lazy(() =>
  import("./components/QuinRide/ProductsList/FullView/ProductFullView")
);
//QuinRideProductGroups
const QuinRideProductGroups = lazy(() =>
  import("./components/QuinRide/ProductGroups/GroupList")
);
const QuinRideProductGroupFullView = lazy(() =>
  import("./components/QuinRide/ProductGroups/GroupFullView/GroupFullView")
);
//QuinRideUsers
const QuinRideUsers = lazy(() =>
  import("./components/QuinRide/Users/QuinRideUsers")
);
const QuinRideUsersDetails = lazy(() =>
  import("./components/QuinRide/Users/UserDetailsTab")
);
//QuinRidePolicies
const QuinRidePolicies = lazy(() =>
  import("./components/QuinRide/Policies/PoliciesMain")
);
const QuinRidePoliciesDetails = lazy(() =>
  import("./components/QuinRide/Policies/PolicyDetails")
);
//Settings
const Settings = lazy(() => import("./components/Settings/Settingsmain"));

// Components with permission

//Anamolies
const AnamoliesWithPermissions = withPermission(Anamolies, ["anamolies_data"]);
//AdminDashboard
const AdminDashboardWithPermission = withPermission(AdminDashboard, [
  "app_stats",
]);
//BLEAnalyzer
const BLEAnalyzerWithPermission = withPermission(BLEAnalyzer, [
  "blueimpact_analyzer",
]);
const EventDetailsWithPermission = withPermission(EventDetailsPage, [
  "blueimpact_analyzer",
]);
//UserInsights
const UserInsightsWithPermission = withPermission(UserInsightsPage, [
  "user_insights",
]);
const UserFullViewWithPermission = withPermission(UserFullView, [
  "user_insights",
]);
//CrashDetails
const CrashDetailsWithPermission = withPermission(CrashDetailsPage, [
  "crash_details",
]);
const CrashReportWithPermission = withPermission(CrashReportPage, [
  "crash_details",
]);
//EmailSignature
const EmailSignatureWithPermission = withPermission(EmailSignature, [
  "email_signature",
]);
//Settings
const SettingsWithPermission = withPermission(Settings, ["admin_settings"]);

//Quin Performance

const PerformanceWithPermission = withPermission(Performance, [
  "quin_performance",
]);

//Quin Performance Content-Text
const PerformanceContentWithPermission = withPermission(PerformanceText, [
  "performance_content_text",
]);
//Quin Performance Content-Image
const PerformanceImagesWithPermission = withPermission(PerformanceImages, [
  "performance_content_images",
]);
//Quin Performance Products
const PerformanceProductsWithPermission = withPermission(PerformanceProducts, [
  "performance_products",
]);
const ProductFullViewWithPermission = withPermission(ProductFullView, [
  "performance_products",
]);

//Quin Performance Sensors
const PerformanceSensorsWithPermission = withPermission(PerformanceSensors, ["quin_performance"])
const SensorFullViewWithPermission = withPermission(SensorFullView, ["quin_performance"])
//Quin Performance Product Groups
const PerformanceProductGroupsWithPermissioin = withPermission(
  PerformanceProductGroups,
  ["performance_product_groups"]
);
const PerformanceProductGroupFullViewWithPermission = withPermission(
  PerformanceProductGroupFullView,
  ["performance_product_groups"]
);
//Quin Performance Users
const PerformanceUsersWithPermission = withPermission(PerformanceUsers, [
  "performance_users",
]);
const PerformanceUsersDetailsWithPermission = withPermission(
  PerformanceUsersDetails,
  ["performance_users"]
);
//Quin Performance Policies
const PerformancePolciesWithPermission = withPermission(PerformancePolicies, [
  "performance_policies",
]);
const PerformancePolicyDetailsWithPermission = withPermission(
  PerformancePoliciesDetails,
  ["performance_policies"]
);

//Quin Ride 2.9

const QuinRideWithPermission = withPermission(QuinRide, ["quin_ride"]);

//Quin Ride 2.9 Content Text
const QuinRideTextWithPermission = withPermission(QuinRideText, [
  "ride_content_text",
]);
//Quin Ride 2.9 Content Image
const QuinRideImageWithPermission = withPermission(QuinRideImage, [
  "ride_content_image",
]);
//Quin Ride 2.9 Product
const QuinRideProductsWithPermission = withPermission(QuinRideProducts, [
  "ride_products",
]);
const QuinRideProductFullViewWithPermissioin = withPermission(
  QuinRideProductFullView,
  ["ride_products"]
);
//Quin Ride 2.9 Product Groups
const QuinRideProductGroupsWithPermission = withPermission(
  QuinRideProductGroups,
  ["ride_product_groups"]
);
const QuinRideProductGroupFullViewWithPermission = withPermission(
  QuinRideProductGroupFullView,
  ["ride_product_groups"]
);
////Quin Ride 2.9 Users
const QuinRideUsersWithPermission = withPermission(QuinRideUsers, [
  "ride_users",
]);
const QuinRideUsersDetailsWithPermission = withPermission(
  QuinRideUsersDetails,
  ["ride_users"]
);
//Quin Ride 2.9 Policies
const QuinRidePolciesWithPermission = withPermission(QuinRidePolicies, [
  "ride_policies",
]);
const QuinRidePolicyDetailsWithPermission = withPermission(
  QuinRidePoliciesDetails,
  ["ride_policies"]
);

const NotFoundPage = () => <h2>Page Not Found</h2>;

const AppContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, login, logout } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [isSidebarMinimized, setSidebarMinimized] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      dispatch(setAuthData({ token }));
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        logout();
        alert("Session expired. Please login again.");
        navigate("/login");
      }
    } else if (location.pathname !== "/login") {
      navigate("/login");
    }
  }, [dispatch, navigate, location.pathname, logout]);

  const isLoginPage = location.pathname === "/login";
  const shouldApplyMainContentClass = isAuthenticated && !isLoginPage;

  return (
    <div className="app-container">
      {isAuthenticated && (
        <Suspense fallback={<div>Loading Sidebar...</div>}>
          <HeaderWrapper />
        </Suspense>
      )}
      <div className={shouldApplyMainContentClass ? "main-content" : ""}>
        {/* <div className={`main-content ${isSidebarMinimized ? "minimized" : ""}`}> */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/home" />
                ) : (
                  <LoginPage onLoginSuccess={login} />
                )
              }
            />
            <Route
              path="/"
              element={
                isAuthenticated ? <Homepage /> : <Navigate to="/login" />
              }
            />
            <Route path="/home" element={<Homepage />} />
            {/* Anomalies */}
            <Route
              path="/admin/anamolies-data"
              element={
                <PrivateRoute>
                  <AnamoliesWithPermissions />
                </PrivateRoute>
              }
            />
            {/* App-Stats */}
            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <AdminDashboardWithPermission />
                </PrivateRoute>
              }
            />
            {/* User-Insights */}
            <Route
              path="/admin/user-insights"
              element={
                <PrivateRoute>
                  <UserInsightsWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/user-insights/:userId"
              element={
                <PrivateRoute>
                  <UserFullViewWithPermission />
                </PrivateRoute>
              }
            />
            {/* Ble-Analyzer */}
            <Route
              path="/admin/ble-analyzer"
              element={
                <PrivateRoute>
                  <BLEAnalyzerWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/eventDetails"
              element={
                <PrivateRoute>
                  <EventDetailsWithPermission />
                </PrivateRoute>
              }
            />
            {/* Email-Signature */}
            <Route
              path="/admin/email-signature"
              element={
                <PrivateRoute>
                  <EmailSignatureWithPermission />
                </PrivateRoute>
              }
            />
            {/* Crash-Details */}
            <Route
              path="/admin/crash"
              element={
                <PrivateRoute>
                  <CrashDetailsWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/crash/reports"
              element={
                <PrivateRoute>
                  <CrashReportWithPermission />
                </PrivateRoute>
              }
            />
            {/* Settings */}
            <Route
              path="/admin/settings"
              element={
                <PrivateRoute>
                  <SettingsWithPermission />
                </PrivateRoute>
              }
            />
            {/* QUIN PERFORMANCE //Performance Content-Text */}

            <Route
              path="/admin/performance"
              element={
                <PrivateRoute>
                  <PerformanceWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/text-content"
              element={
                <PrivateRoute>
                  <PerformanceContentWithPermission />
                </PrivateRoute>
              }
            />
            {/* Performance Content-Images */}
            <Route
              path="/admin/performance/image-content"
              element={
                <PrivateRoute>
                  <PerformanceImagesWithPermission />
                </PrivateRoute>
              }
            />
            {/* Performance Products */}
            <Route
              path="/admin/performance/products"
              element={
                <PrivateRoute>
                  <PerformanceProductsWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/products/:productId"
              element={
                <PrivateRoute>
                  <ProductFullViewWithPermission />
                </PrivateRoute>
              }
            />

            {/* Performance Sensors */}
            <Route
              path="/admin/performance/sensors"
              element={
                <PrivateRoute>
                  <PerformanceSensorsWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/sensors/:sensorId"
              element={
                <PrivateRoute>
                  <SensorFullViewWithPermission />
                </PrivateRoute>
              }
            />

            {/* Performance Product-Groups */}
            <Route
              path="/admin/performance/products-groups"
              element={
                <PrivateRoute>
                  <PerformanceProductGroupsWithPermissioin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/products-groups/:groupId"
              element={
                <PrivateRoute>
                  <PerformanceProductGroupFullViewWithPermission />
                </PrivateRoute>
              }
            />
            {/* Performance Users */}
            <Route
              path="/admin/performance/users"
              element={
                <PrivateRoute>
                  <PerformanceUsersWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/users/:userId"
              element={
                <PrivateRoute>
                  <PerformanceUsersDetailsWithPermission />
                </PrivateRoute>
              }
            />
            {/* Performance Policies */}
            <Route
              path="/admin/performance/policies"
              element={
                <PrivateRoute>
                  <PerformancePolciesWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/policies/:policyId"
              element={
                <PrivateRoute>
                  <PerformancePolicyDetailsWithPermission />
                </PrivateRoute>
              }
            />
            {/* QUIN RIDE //QuinRide 2.9 Content-Text */}
            <Route
              path="/admin/quinride/content-text"
              element={
                <PrivateRoute>
                  <QuinRideTextWithPermission />
                </PrivateRoute>
              }
            />
            {/* QuinRide 2.9 Content-Image */}
            <Route
              path="/admin/quinride/image-content"
              element={
                <PrivateRoute>
                  <QuinRideImageWithPermission />
                </PrivateRoute>
              }
            />
            {/* QuinRide 2.9 Users */}
            <Route
              path="/admin/quinride/users"
              element={
                <PrivateRoute>
                  <QuinRideUsersWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/quinride/users/:userId"
              element={
                <PrivateRoute>
                  <QuinRideUsersDetailsWithPermission />
                </PrivateRoute>
              }
            />
            {/* QuinRide 2.9 Products */}
            <Route
              path="/admin/quin_ride"
              element={
                <PrivateRoute>
                  <QuinRideWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/quinride/products"
              element={
                <PrivateRoute>
                  <QuinRideProductsWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/performance/products/:productId"
              element={
                <PrivateRoute>
                  <QuinRideProductFullViewWithPermissioin />
                </PrivateRoute>
              }
            />
            {/* QuinRide 2.9 Product-Groups */}
            <Route
              path="/admin/quinride/products-groups"
              element={
                <PrivateRoute>
                  <QuinRideProductGroupsWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/quinride/products-groups/:groupId"
              element={
                <PrivateRoute>
                  <QuinRideProductGroupFullViewWithPermission />
                </PrivateRoute>
              }
            />
            {/* QuinRide 2.9 Policies */}
            <Route
              path="/admin/quinride/policies"
              element={
                <PrivateRoute>
                  <QuinRidePolciesWithPermission />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/quinride/policies/:policyId"
              element={
                <PrivateRoute>
                  <QuinRidePolicyDetailsWithPermission />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

const WithAuth = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WithAuth;
