import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MultiActionAreaCard from "../MultiActionAreaCard/MultiActionAreaCard";
import "./QuinRideCards.css";
import { Typography } from "@mui/material";

const QuinRideCards = () => {
  const location = useLocation();
  const selectedItem = location.pathname.split("/")[2];
  const permissions = useSelector((state) => state.auth.permissions);
  const hasPermission = (perm) => permissions.includes(perm);
  return (
    <>
      {" "}
      <Typography
        variant="h4"
        className="performance-title"
        style={{ margin: "20px", fontFamily: "Poppins", textAlign: "center" }}
      >
        Quin Ride 2.9
      </Typography>
      <div className="sub-card-container">
        {hasPermission("ride_content_text") && (
          <Link
            to="/admin/quinride/content-text"
            style={{ textDecoration: "none" }}
          >
            <MultiActionAreaCard
              title="Quin Ride Text"
              description="Written content optimized for efficiency, clarity, and impact."
              image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/beef81eb-5372-44d1-9f5e-33b37d618dc2.png"
              isSelected={selectedItem === "content-text"}
            />
          </Link>
        )}
        {/* {hasPermission("ride_content_image") && (
          <Link
            to="/admin/quinride/image-content"
            style={{ textDecoration: "none" }}
          >
            <MultiActionAreaCard
              title="Quin Ride Images"
              description="Visual elements optimized for quality, relevance, and impact."
              image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/efacc75d-a1a2-40f0-93a1-4f336b694fde.png"
              isSelected={selectedItem === "image-content"}
            />
          </Link>
        )}

        {hasPermission("ride_products") && (
          <Link
            to="/admin/quinride/products"
            style={{ textDecoration: "none" }}
          >
            <MultiActionAreaCard
              title="Quin Ride Products"
              description="Goods or services optimized for superior performance."
              image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/8375d4d1-3746-4f96-bdd2-c1d04924f406.png"
              isSelected={selectedItem === "products"}
            />
          </Link>
        )}

        {hasPermission("ride_product_groups") && (
          <Link
            to="/admin/quinride/products-groups"
            style={{ textDecoration: "none" }}
          >
            <MultiActionAreaCard
              title="Quin Ride Product Groups"
              description="Groups of goods or services optimized for superior performance."
              image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/838237a5-2ca4-4cb5-b2e3-61e79ec21717.jpeg"
              isSelected={selectedItem === "product-groups"}
            />
          </Link>
        )}

        {hasPermission("ride_users") && (
          <Link to="/admin/quinride/users" style={{ textDecoration: "none" }}>
            <MultiActionAreaCard
              title="Quin Ride Users"
              description="Individuals or entities who interact with a product or service."
              image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/177ecacc-8f37-4928-bd45-b101e5700d07.png"
              isSelected={selectedItem === "users"}
            />
          </Link>
        )}

        {hasPermission("ride_policies") && (
          <Link
            to="/admin/quinride/policies"
            style={{ textDecoration: "none" }}
          >
            <MultiActionAreaCard
              title="Quin Ride Policies"
              description="Written guidelines optimized for quality, relevance, and impact."
              image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/0cd1e374-895c-41bd-af5b-73c47fa95026.png"
              isSelected={selectedItem === "policies"}
            />
          </Link>
        )} */}
      </div>
    </>
  );
};

export default QuinRideCards;
