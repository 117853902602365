import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ApiErrorPopup from "../ApiErrorPopup";

const withPermission = (WrappedComponent, requiredPermissions) => {
  const WithPermissionComponent = (props) => {
    const { permissions } = useSelector((state) => state.auth);
    const hasPermission = requiredPermissions.every((perm) => permissions.includes(perm));
    const [showPopup, setShowPopup] = useState(!hasPermission);

    useEffect(() => {
      if (!hasPermission) {
        setShowPopup(true);
      }
    }, [hasPermission]);

    const handleClose = () => {
      setShowPopup(false);
      window.location.href = "/home";
    };

    if (!hasPermission) {
      return showPopup ? <ApiErrorPopup code="401" message="You do not have permission to access this page." onClose={handleClose} /> : null;
    }

    return <WrappedComponent {...props} />;
  };

  return WithPermissionComponent;
};

export default withPermission;
