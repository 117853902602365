import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAuth } from "./utils/AuthContext";
import { useSelector } from "react-redux";
import styles from "./Header.module.css";
import { Collapse } from "@mui/material";

const drawerWidth = 240;

const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const permissions = useSelector((state) => state.auth.permissions);
  const location = useLocation();

  const selectedItem = location.pathname.split("/")[2];
  const subItem = location.pathname.split("/")[3]; // Added line to capture sub-item
  const hasPermission = (perm) => permissions.includes(perm);

  const [open, setOpen] = useState(false);
  const [isQuinPerformanceSelected, setIsQuinPerformanceSelected] =
    useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Add a new state variable for Quin 2.9 section
  const [isQuinRideSelected, setIsQuinRideSelected] = useState(false);

  // Function to toggle visibility of "Quin 2.9" section
  const handleQuinRideToggle = () => {
    setIsQuinRideSelected(!isQuinRideSelected);
  };

  return (
    <>
      <header className={styles.appHeader}>
        <div className={styles.sidebar}>
          <IconButton
            className={styles.menuToggle}
            aria-label="menu"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={handleDrawerOpen}
          >
            <MenuIcon
              style={{
                backgroundColor: "#4EF8E8",
                borderRadius: "5px",
                padding: "0px",
                marginRight: "5px",
              }}
            />
          </IconButton>
          <Link to="/home">
            <img
              src={
                "https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/b55c9d12-548f-4f25-98ed-c201c3ce2dd8.png"
              }
              alt="Quin Insights Logo"
              className={styles.logo}
            />
          </Link>
        </div>
        <div>
          {isAuthenticated ? (
            <button onClick={logout} className={styles.logoutButton}>
              Logout
            </button>
          ) : (
            <div className={styles.invisiblePlaceholder}></div>
          )}
        </div>
      </header>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        onMouseLeave={handleDrawerClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "black", // Set background color of drawer
            color: "white", // Set text color to white
          },
        }}
      >
        <div>
          <IconButton onClick={handleDrawerClose} style={{ color: "#4EF8E8" }}>
            <img
              src="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/b55c9d12-548f-4f25-98ed-c201c3ce2dd8.png"
              alt="Quin Insights Logo"
              style={{
                marginRight: "15px",
                marginTop: "10px",
                maxWidth: "70%",
                maxHeight: "100%",
              }}
            />
            {/* <ChevronLeftIcon /> */}
          </IconButton>
        </div>
        <List>

          {hasPermission("anamolies_data") && (
            <ListItem
              component={Link}
              to="/admin/anamolies-data"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color:
                    selectedItem === "anamolies-data" ? "#4EF8E8" : "white", // Set text color of primary text
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="Anomalies" />
            </ListItem>
          )}

          {hasPermission("app_stats") && (
            <ListItem
              component={Link}
              to="/admin/dashboard"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color: selectedItem === "dashboard" ? "#4EF8E8" : "white", // Set text color of primary text
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="Application Stats" />
            </ListItem>
          )}

          {hasPermission("blueimpact_analyzer") && (
            <ListItem
              component={Link}
              to="/admin/ble-analyzer"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color: selectedItem === "ble-analyzer" ? "#4EF8E8" : "white",
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="Bluetooth Testing" />
            </ListItem>
          )}

          {hasPermission("crash_details") && (
            <ListItem
              component={Link}
              to="/admin/crash"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color: selectedItem === "crash" ? "#4EF8E8" : "white",
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="Crash Details" />
            </ListItem>
          )}

          {hasPermission("user_insights") && (
            <ListItem
              component={Link}
              to="/admin/user-insights"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color: selectedItem === "user-insights" ? "#4EF8E8" : "white",
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="User Insights" />
            </ListItem>
          )}

          {hasPermission("email_signature") && (
            <ListItem
              component={Link}
              to="/admin/email-signature"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color:
                    selectedItem === "email-signature" ? "#4EF8E8" : "white",
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="Email Signature" />
            </ListItem>
          )}

          {hasPermission("quin_performance") && (
            <>
              <ListItem
                onClick={() =>
                  setIsQuinPerformanceSelected(!isQuinPerformanceSelected)
                }
                className={`${styles.boldListItem}`}
                sx={{
                  "& .MuiListItemText-primary": {
                    color:
                      selectedItem === "quin-performance" ? "#4EF8E8" : "white",
                    fontWeight: 900,
                  },
                }}
              >
                <ListItemText primary="Quin Performance" />
              </ListItem>
              <Collapse
                in={isQuinPerformanceSelected}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{ padding: "10px", marginRight: "100px" }}
                >
                  {hasPermission("performance_content_text") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/text-content"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "text-content" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary="Text" />
                    </ListItem>
                  )}
                  {hasPermission("performance_content_images") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/image-content"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            subItem === "image-content" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary="Images" />
                    </ListItem>
                  )}

                  {hasPermission("performance_products") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/products"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "products" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Products" />
                    </ListItem>
                  )}
                  {hasPermission("performance_product_groups") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/products-groups"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            subItem === "products-groups" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Product Groups" />
                    </ListItem>
                  )}

                  {hasPermission("performance_sensors") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/sensors"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            subItem === "sensors" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Sensors" />
                    </ListItem>
                  )}
                  {hasPermission("performance_users") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/users"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "users" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Users" />
                    </ListItem>
                  )}

                  {hasPermission("performance_policies") && (
                    <ListItem
                      component={Link}
                      to="/admin/performance/policies"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "policies" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary="policies" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {/* Quin 2.9 section */}
          {hasPermission("quin_ride") && (
            <>
              <ListItem
                onClick={() => handleQuinRideToggle(!isQuinRideSelected)}
                className={`${styles.boldListItem}`}
                sx={{
                  "& .MuiListItemText-primary": {
                    color:
                      selectedItem === "quin-performance" ? "#4EF8E8" : "white",
                    fontWeight: 900,
                  },
                }}
              >
                <ListItemText primary="Quin Ride 2.9" />
              </ListItem>
              <Collapse in={isQuinRideSelected} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  style={{ padding: "10px", marginRight: "100px" }}
                >
                  {hasPermission("ride_content_text") && (
                    <ListItem
                      component={Link}
                      to="/admin/quinride/content-text"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "content-text" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Text" />
                    </ListItem>
                  )}

                  {hasPermission("ride_content_image") && (
                    <ListItem
                      component={Link}
                      to="/admin/quinride/image-content"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            subItem === "image-content" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary="images" />
                    </ListItem>
                  )}

                  {hasPermission("ride_products") && (
                    <ListItem
                      component={Link}
                      to="/admin/quinride/products"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "products" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Products" />
                    </ListItem>
                  )}
                  {hasPermission("ride_product_groups") && (
                    <ListItem
                      component={Link}
                      to="/admin/quinride/products-groups"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            subItem === "products-groups" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary="Brands and Sensors" />
                    </ListItem>
                  )}
                  {hasPermission("ride_users") && (
                    <ListItem
                      component={Link}
                      to="/admin/quinride/users"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "users" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary=" Users" />
                    </ListItem>
                  )}

                  {hasPermission("ride_policies") && (
                    <ListItem
                      component={Link}
                      to="/admin/quinride/policies"
                      className={`${styles.boldListItem}`}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: subItem === "products" ? "#4EF8E8" : "white",
                          fontWeight: 900,
                        },
                      }}
                    >
                      <ListItemText primary="policies" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {hasPermission("admin_settings") && (
            <ListItem
              component={Link}
              to="/admin/settings"
              className={`${styles.boldListItem}`}
              sx={{
                "& .MuiListItemText-primary": {
                  color: selectedItem === "settings" ? "#4EF8E8" : "white",
                  fontWeight: 900,
                },
              }}
            >
              <ListItemText primary="Settings" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
